import React, { useEffect, useState } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import axios from "axios";


const Location = () => {
  const [data, setdata] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://senpai.co.in/admin/api/a1/getLocationList"
        );
        // console.log(response.data.data);
        const locationData = response.data.data;
        const bestDeals = locationData.filter(
          (item) => item.tag_name === "Exclusive offer\r\n"
        );
        setdata(bestDeals);
        console.log("TV Ads",bestDeals);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);


  return (
    <Container className="">
      <Row>
        {data.map((location, index , exact) => (
          <Col sm={3} key={index}  exact={`${exact}`} className="mb-4">
            <a href={`/locationdetalis/${location.location_id}`}>
              <div className="bestlocation">
                <Image src={location.image[0]} alt="" />
                <div className="position-relative">
                  <p>{location.location_name}</p>
                </div>
              </div>
            </a>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Location;
