import React, { useState, useEffect } from "react";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { toast } from "react-toastify";
import { google } from "./imgUrl";
import { useNavigate } from "react-router-dom";

function Google() {
  const [social_id, setSocialId] = useState("");
  const [is_social, setIsSocial] = useState("");
  const [email_id, setEmailId] = useState("");
  const [social_type, setSocialType] = useState("");
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState(null);
  const [error, setError] = useState(null);

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => {
      setError("Login Failed: " + error.message);
    },
  });

  useEffect(() => {
    if (user && user.access_token) {
      const fetchProfileData = async () => {
        try {
          const res = await axios.get(
            `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`
          );
          setProfile(res.data);

          // Call the social login API here
          if (res.data && res.data.email) {
            const data = {
              social_id: res.data.id,
              is_social: 1,
              social_type: 2, // 2 for Google, 1 for Facebook
              email_id: res.data.email,
            };
            // Update the state with the social login data
            setSocialId(data.social_id);
            setIsSocial(data.is_social);
            setSocialType(data.social_type);
            setEmailId(data.email_id);
          }
        } catch (err) {
          setError("Google API Error: " + err.message);
        }
      };
      fetchProfileData();
    }
  }, [user]);

  useEffect(() => {
    if (localStorage.getItem("isLoggedIn") === "true") {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    // Save the name to local storage
    if (profile && profile.name) {
      localStorage.setItem("isLoggedIn", "true"); // Store login status
      localStorage.setItem("name", profile.name);
    }
  }, [profile]);

  // log out function to log the user out of google and set the profile array to null
  const logOut = () => {
    localStorage.removeItem("isLoggedIn"); // Remove login status
    localStorage.removeItem("name"); // Remove username
    navigate("/login");
    googleLogout();
    setProfile(null);
    setError(null);
  };

  useEffect(() => {
    // Autosubmit the form when the necessary states are set
    if (social_id && is_social && email_id && social_type) {
      handleSubmit();
    }
  }, [social_id, is_social, email_id, social_type]);

  const handleSubmit = async () => {
    var data = new FormData();
    data.append("social_id", social_id);
    data.append("is_social", is_social);
    data.append("email_id", email_id);
    data.append("social_type", social_type);

    var config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://senpai.co.in/admin/api/a1/socialLogin",
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
      },
      data: data,
    };

    try {
      const response = await axios(config);
      console.log(JSON.stringify(response.data));
      toast.success("SuccessFull");
      navigate("/");
    } catch (error) {
      console.log(error);
      toast.error("Failed " + error.message);
    }
  };

  return (
    <div>
      {error && <p style={{ color: "red" }}>{error}</p>}
      {profile ? (
        <div className="d-none">
          <p>Name: {profile.name}</p>
          <form >
            <input
              type="text"
              name="social_id"
              placeholder="Social ID"
              value={social_id}
              onChange={(e) => setSocialId(e.target.value)}
              readOnly
            />
            <input
              type="text"
              name="is_social"
              placeholder="Is Social"
              value={is_social}
              onChange={(e) => setIsSocial(e.target.value)}
              readOnly
            />
            <input
              type="text"
              name="social_type"
              placeholder="Social Type"
              value={social_type}
              onChange={(e) => setSocialType(e.target.value)}
              readOnly
            />
            <input
              type="text"
              name="email_id"
              placeholder="Email ID"
              value={email_id}
              onChange={(e) => setEmailId(e.target.value)}
              readOnly
            />
            {/* Remove the submit button, as we're autosubmitting the form */}
          </form>
          <br />
          <br />
          <button onClick={logOut}>Log out</button>
        </div>
      ) : (
        <button onClick={() => login()} className="btn btn-google px-4">
          <img src={google} className="me-2" alt="Google" />
          Google
        </button>
      )}
    </div>
  );
}

export default Google;
