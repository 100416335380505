import { useEffect, useState } from "react";
import {
  location9,
  location10,
  location11,
  location12,
  location13,
  location14,
  location16,
  location17,
  about,
} from "./imgUrl";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import Location, { Locationslider } from "./Location";
import LocationType from "./LocationType";
import Testimonials from "./Testimonials";
import SearchLocation from "./SearchLocation";
import { Image } from "react-bootstrap";
import HomeSlider from "./HomeSlider";
import Bestdeals from "./Bestdeals";
import TVAds from "./TVAds";

const Banner = () => {
  return (
    <div>
      <div className="position-relative">
        <HomeSlider />
      </div>
      <section className="mt-100">
        <SearchLocation />
      </section>

      <section className="mt-100">
        <div className="container">
          <Bestdeals />

          <section className="mt-100">
            <div className="">
              <div className="row p-0">
                <div className="col-sm-6 ps-lg-0 ">
                  <LocationType />
                </div>
                <div className="col-sm-6">
                  <Testimonials />
                </div>
              </div>
            </div>
          </section>

          <section className="mt-100">
            <div className="row align-items-center ">
              <div className="col-sm-6">
                <h1 className="top-heading-section my-3  text-start">
                  Exclusive <span>properties </span>Price on Request
                </h1>
              </div>
              <div className="col-sm-6 text-lg-end text-center mb-lg-0 mb-3">
                <button className="btn btn-comman px-5 py-1">call now</button>
              </div>
            </div>
          </section>

          <Location />

          <section className="mt-100">
            <div className="container">
              <h1 className="top-heading-section my-3">
                <span> About</span> us
              </h1>
              <div className="row align-items-center">
                <div className="col-sm-6">
                  <img src={about} className="w-full" alt="" />
                </div>
                <div className="col-sm-6">
                  <div className="about-section">
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Eum, labore. Temporibus reiciendis fugiat sit libero
                      quasi? Architecto, rem! Amet facilis ducimus dolores,
                      omnis corrupti laudantium ex quidem quae aut assumenda.
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                      Quis culpa id facere libero ad, maxime modi earum
                      praesentium delectus officiis inventore suscipit rem non
                      explicabo quidem neque dolores pariatur? Veritatis?
                      <br />
                      <br />
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Ratione perspiciatis sint, porro ab incidunt amet quasi
                      non voluptatibus maiores commodi consequuntur earum
                      exercitationem aperiam natus possimus nulla dolorem ipsa
                      omnis!
                    </p>
                    <Link className="btn px-4" to="/about">
                      read more
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>

      <section className="mt-100">
        <TVAds />
      </section>

      <section className="mt-100">
        <div className="container">
          <h1 className="top-heading-section my-3">
            Unable to find what <span> you want? </span>
          </h1>
          <div className="text-center mt-5">
            <Link to={"/contact"} className="btn btn-comman px-5">get in touch</Link>
          </div>
        </div>
      </section>

      <section className="mt-100">
        <div className="container mb-5">
          <h1 className="top-heading-section my-3 ">
            Have a property to <span> list?</span>
          </h1>
          <div className="row align-items-center justify-content-around">
            <div className="col-sm-6">
              <img src={about} className="w-full" alt="" />
            </div>
            <div className="col-auto">
              <div className="text-center mt-5">
                <Link to="/login" className="btn btn-comman px-5">
                  login/signup
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Banner;
