import React, { useEffect, useState } from "react";
import { Container, Row, Col, Image, Button, Accordion } from "react-bootstrap";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import Slider, { Range } from "rc-slider";
import "rc-slider/assets/index.css";
const Findproperties = () => {
  const id = useParams();
  console.log("locationtypoid", id);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState([]);
  const [locationcity, setLocationcity] = useState([]);
  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [property, setProperty] = useState([]);
  const [activeCategory, setActiveCategory] = useState(null);
  const [activeCity, setActiveCity] = useState(null);
  const [activeCountry, setActiveCountry] = useState(null);
  const [activeState, setActiveState] = useState(null);
  const [activeProperty, setActiveProperty] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortData, setSortData] = useState([]);
  const [priceRange, setPriceRange] = useState([0, 50000]);
  const [sizeRange, setSizeRange] = useState([0, 200]);
  const [taxRange, setTaxRange] = useState([0, 50000]);

  const handlePriceRangeChange = (newPriceRange) => {
    setPriceRange(newPriceRange);
    applyFilters();
  };

  const handleSizeRangeChange = (newSizeRange) => {
    setSizeRange(newSizeRange);
    applyFilters();
  };

  const handleTaxRangeChange = (newTaxRange) => {
    setTaxRange(newTaxRange);
    applyFilters();
  };

  const applyFilters = () => {
    // Apply the filters based on the updated range values
    const filteredList = data.filter(
      (item) =>
        item.price >= priceRange[0] &&
        item.price <= priceRange[1] &&
        item.size_area >= sizeRange[0] &&
        item.size_area <= sizeRange[1] &&
        item.tax_to_accommodate >= taxRange[0] &&
        item.tax_to_accommodate <= taxRange[1] &&
        (activeCategory
          ? item.category_name.toLowerCase() === activeCategory.toLowerCase()
          : true) &&
        (activeCity ? item.city_name === activeCity : true) &&
        (activeCountry ? item.country_name === activeCountry : true) &&
        (activeState ? item.state_name === activeState : true) &&
        (activeProperty ? item.property_owner === activeProperty : true)
    );

    setFilter(filteredList);
  };

  useEffect(() => {
    fetchData();
    fetchLocationcity();
    fetchCities();
    fetchCountries();
    fetchStates();
    fetchProperty();

    setActiveCategory(id.category_type || null);
  }, []);

  const fetchData = async (val) => {
    const response = await axios.get(
      `https://senpai.co.in/admin/api/a1/getAllFilter/?category_type=${
        val || id.category_type
      }`
    );
    setData(response.data.data.sub_location_data);
    setFilter(response.data.data.sub_location_data);
    console.log(response.data.data.sub_location_data);
  };

  const fetchLocationcity = () => {
    axios
      .get("https://senpai.co.in/admin/api/a1/getLocationType")
      .then((response) => {
        setLocationcity(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching location city:", error);
      });
  };

  const fetchCities = () => {
    axios
      .get("https://senpai.co.in/admin/api/a1/getCity")
      .then((response) => {
        setCities(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching cities:", error);
      });
  };

  const fetchCountries = () => {
    axios
      .get("https://senpai.co.in/admin/api/a1/getCountry")
      .then((response) => {
        setCountries(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching countries:", error);
      });
  };

  const fetchStates = () => {
    axios
      .get("https://senpai.co.in/admin/api/a1/getState")
      .then((response) => {
        setStates(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching states:", error);
      });
  };

  const fetchProperty = () => {
    axios
      .get("https://senpai.co.in/admin/api/a1/getPropertyOwner")
      .then((response) => {
        setProperty(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching Property:", error);
      });
  };

  const filterProduct = (
    category,
    city,
    country,
    state,
    propertyOwner,
    search,
    min_price,
    max_price,
    min_size,
    max_size,
    min_tax,
    max_tax
  ) => {
    console.log("shamim", data);
    let updatedList = data;
    let updatedCategory = activeCategory;
    let updatedCity = activeCity;
    let updatedCountry = activeCountry;
    let updatedState = activeState;
    let updatedProperty = activeProperty;

    if (min_price && max_price) {
      updatedList = updatedList.filter(
        (x) => x.price >= min_price && x.price <= max_price
      );
      setSortData(updatedList);
    }

    if (min_size && max_size) {
      // Filter by size area range
      updatedList = updatedList.filter(
        (x) => x.size_area >= min_size && x.size_area <= max_size
      );
      setSortData(updatedList);
    }

    if (min_tax && max_tax) {
      // Filter by size area range
      updatedList = updatedList.filter(
        (x) =>
          x.tax_to_accommodate >= min_tax && x.tax_to_accommodate <= max_tax
      );
      setSortData(updatedList);
    }

    if (category) {
      if (activeCategory === category) {
        updatedCategory = null;
      } else {
        updatedCategory = category;
        setSortData(category);
      }
      fetchData(category);
    }

    if (city) {
      if (activeCity === city) {
        updatedCity = null;
      } else {
        updatedCity = city;
        setSortData(city);
      }
    }

    if (country) {
      if (activeCountry === country) {
        updatedCountry = null;
      } else {
        updatedCountry = country;
        setSortData(country);
      }
    }

    if (state) {
      if (activeState === state) {
        updatedState = null;
      } else {
        updatedState = state;
        setSortData(state);
      }
    }

    if (propertyOwner) {
      if (activeProperty === propertyOwner) {
        updatedProperty = null;
      } else {
        updatedProperty = propertyOwner;
        setSortData(propertyOwner);
      }
    }

    setActiveCategory(updatedCategory);
    setActiveCity(updatedCity);
    setActiveCountry(updatedCountry);
    setActiveState(updatedState);
    setActiveProperty(updatedProperty);

    updatedList = updatedList.filter(
      (item) =>
        (updatedCategory
          ? item.category_name.toLowerCase() === updatedCategory.toLowerCase()
          : true) &&
        (updatedCity ? item.city_name === updatedCity : true) &&
        (updatedCountry ? item.country_name === updatedCountry : true) &&
        (updatedState ? item.state_name === updatedState : true) &&
        (updatedProperty ? item.property_owner === updatedProperty : true)
    );

    if (updatedCategory) {
      updatedList = updatedList.filter(
        (x) => x.category_name.toLowerCase() === updatedCategory.toLowerCase()
      );
      setSortData(updatedList);

      console.log("updatedListupdatedList", updatedList);
    }

    if (updatedCity) {
      updatedList = updatedList.filter((x) => x.city_name === updatedCity);
      console.log(updatedList, "lllllllllllllllllllllllllllll");
      setSortData(updatedList);
    }

    if (updatedCountry) {
      updatedList = updatedList.filter(
        (x) => x.country_name === updatedCountry
      );
      setSortData(updatedList);
    }

    if (updatedState) {
      updatedList = updatedList.filter((x) => x.state_name === updatedState);
      setSortData(updatedList);
    }

    if (updatedProperty) {
      updatedList = updatedList.filter(
        (x) => x.property_owner === updatedProperty
      );
      setSortData(updatedList);
    }

    if (search) {
      updatedList = updatedList.filter((x) =>
        x.sub_location_name.toLowerCase().includes(search.toLowerCase())
      );
      setFilter(updatedList);
      setSortData(updatedList);
    } else {
      setFilter(updatedList);
      setSortData(updatedList);
    }

    let sortedList = [...sortData];
    setFilter(updatedList);
    console.log(sortedList);
  };

  const handleSortClick = () => {
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(newSortOrder);

    setFilter((prevFilter) => {
      const sortedList = [...prevFilter].sort((a, b) => {
        const nameA = a.sub_location_name.toLowerCase();
        const nameB = b.sub_location_name.toLowerCase();

        if (nameA < nameB) return newSortOrder === "asc" ? -1 : 1;
        if (nameA > nameB) return newSortOrder === "asc" ? 1 : -1;
        return 0;
      });

      return sortedList;
    });
  };

  const handleSearchChange = (e) => {
    const searchQuery = e.target.value;
    setSearchQuery(searchQuery);

    filterProduct(
      activeCategory,
      activeCity,
      activeCountry,
      activeState,
      activeProperty,
      searchQuery
    );
  };

  return (
    <Container className="py-5">
      <h1 className="top-heading-section my-3">Find your properties</h1>

      <div className="searchmain">
        <div className="search-box mt-3">
          <div className="inputbox-main position-relative row justify-content-center">
            <div className="col-sm-6 position-relative">
              <i className="far fa-map-marker-alt"></i>
              <input
                type="text"
                className="form-control"
                placeholder="Enter city..."
                value={searchQuery}
                onChange={handleSearchChange} // Add onChange event handler
              />
              <i className="far fa-search"></i>
            </div>
            <div className="col-auto">
              <button className="px-4">Search</button>
            </div>
          </div>
          <Button className="sort-alpha" onClick={handleSortClick}>
            {sortOrder === "asc" ? "Sort (Ascending)" : "Sort (Descending)"}
          </Button>
        </div>
      </div>
      <Row className="mt-5">
        <div className="col-sm-2">
          <div className="side-box">
            <h4>Filters</h4>
            <div>
              <h3>Property Type</h3>
              <ul>
                {locationcity.map((location) => (
                  <li
                    key={location.category_id}
                    className={`me-2 ${
                      activeCategory === location.category_name ? "active" : ""
                    }`}
                    type="button"
                    data-number="3"
                    onClick={() =>
                      filterProduct(
                        location.category_name,
                        null,
                        null,
                        null,
                        null
                      )
                    }
                  >
                    {location.category_name}
                  </li>
                ))}
              </ul>
            </div>
            <h3>Location</h3>

            <Accordion>
              <Accordion.Item eventKey="cityName">
                <Accordion.Header>City Name</Accordion.Header>
                <Accordion.Body className="px-0 ">
                  <ul className="mb-0">
                    {cities.map((location) => (
                      <li
                        key={location.category_id}
                        className={`me-2 ${
                          activeCity === location.city_name ? "active" : ""
                        }`}
                        type="button"
                        data-number="3"
                        onClick={() =>
                          filterProduct(
                            null,
                            location.city_name,
                            null,
                            null,
                            null
                          )
                        }
                      >
                        {location.city_name}
                      </li>
                    ))}
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="countryName">
                <Accordion.Header>Country Name</Accordion.Header>
                <Accordion.Body className="px-0 ">
                  <ul className="mb-0">
                    {countries.map((location) => (
                      <li
                        key={location.category_id}
                        className={`me-2 ${
                          activeCountry === location.country_name
                            ? "active"
                            : ""
                        }`}
                        type="button"
                        data-number="3"
                        onClick={() =>
                          filterProduct(
                            null,
                            null,
                            location.country_name,
                            null,
                            null
                          )
                        }
                      >
                        {location.country_name}
                      </li>
                    ))}
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="stateName">
                <Accordion.Header>State Name</Accordion.Header>
                <Accordion.Body className="px-0 ">
                  <ul className="mb-0">
                    {states.map((location) => (
                      <li
                        key={location.category_id}
                        className={`me-2 ${
                          activeState === location.state_name ? "active" : ""
                        }`}
                        type="button"
                        data-number="3"
                        onClick={() =>
                          filterProduct(
                            null,
                            null,
                            null,
                            location.state_name,
                            null
                          )
                        }
                      >
                        {location.state_name}
                      </li>
                    ))}
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <h3>Property Owner</h3>

            <ul>
              {property.map((owner) => (
                <li
                  key={owner.category_id}
                  className={`me-2 ${
                    activeProperty === owner.property_owner ? "active" : ""
                  }`}
                  type="button"
                  data-number="3"
                  onClick={() =>
                    filterProduct(null, null, null, null, owner.property_owner)
                  }
                >
                  {owner.property_owner}
                </li>
              ))}
            </ul>

            <div>
              <h3>Budget</h3>
              <Slider
                range
                min={0}
                max={50000}
                value={priceRange}
                onChange={handlePriceRangeChange}
              />

              <div className="d-flex justify-content-between">
                <p> {priceRange[0]}</p>
                <p> {priceRange[1]}</p>
              </div>
            </div>

            <div>
              <h3>Size Area</h3>
              <Slider
                range
                min={0}
                max={200}
                value={sizeRange}
                onChange={handleSizeRangeChange}
              />

              <div className="d-flex justify-content-between">
                <p> {sizeRange[0]}</p>
                <p> {sizeRange[1]}</p>
              </div>
            </div>

            <div>
              <h3>Tax to accommodate</h3>
              <Slider
                range
                min={0}
                max={50000}
                value={taxRange}
                onChange={handleTaxRangeChange}
              />

              <div className="d-flex justify-content-between">
                <p> {taxRange[0]}</p>
                <p> {taxRange[1]}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-10">
          <div className="text-end mb-3"></div>
          {filter.length === 0 ? (
            <p>Data Not Found</p>
          ) : (
            filter.map((item, index) => (
              <Link key={index} to={`/productpage/${item.sub_location_id}`}>
                <div className="listb-box mb-5">
                  <div className="row">
                    <div className="col-auto">
                      <Image src={item.sub_location_images[0]} alt="" />
                    </div>
                    <div className="col">
                      <h2>{item.sub_location_name}</h2>
                      <p>{item.sub_description}</p>
                      <p>{item.price}</p>
                      <p>{item.size_area}</p>
                      <p>{item.tax_to_accommodate}</p>
                    </div>
                  </div>
                  <div className="text-end mt-2">
                    <Link
                      className="btn btn-booknow px-5"
                      key={index}
                      to={{
                        pathname: "/bookings",
                        search: `?property=${item.sub_location_id}&country=${item.country_name}&state=${item.state_name}&city=${item.city_name}&category_id=${item.category_id}&location_id=${item.location_id}&property_owner=${item.property_owner}&sub_location_id=${item.sub_location_id}&propertyName=${item.sub_location_name}&country_id=${item.country_id}&state_id=${item.state_id}&city_id=${item.city_id}&property_owner_id=${item.property_owner_id}`,
                      }}
                    >
                      Book Now
                    </Link>
                  </div>
                </div>
              </Link>
            ))
          )}
        </div>
      </Row>
    </Container>
  );
};

export default Findproperties;
