import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import Sublocation from "./Sublocation";
import { useParams } from "react-router-dom";

const Locationdetails = () => {
  const { locationid } = useParams();
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://senpai.co.in/admin/api/a1/getLocationDetail?location_id=${locationid}`
      );
      setdata(response.data.data);
      setLoading(false);
    } catch (error) {
      setError("Error fetching data");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [locationid]);

  const owlOptions = {
    loop: true,
    margin: 20,
    items: 1.5,
    dots: true,
    nav: true,
    center:true,
    navText: [
      "<i class='fas fa-chevron-left'></i>",
      "<i class='fas fa-chevron-right'></i>",
    ],
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <OwlCarousel className="owl-theme" {...owlOptions}>
        {data?.image?.map((val, index) => (
          <div className="image-slider" key={index}>
            <img src={val} alt="" />
          </div>
        ))}
      </OwlCarousel>
      <Container>
        <Row>
          <Col sm={8}>
            <h1 className="top-heading-section  text-start">
              AS <span> {data?.location_name} </span> 01
            </h1>

            <div className="location-dsescition">
              <p>{data?.description}</p>
            </div>
            <Row>
              <Col sm="auto" className="ps-0">
                <div className="size-area">
                  <h6>Size/Area</h6>
                  <p>2 sqft</p>
                </div>
              </Col>
              <Col sm="auto">
                <div className="size-area">
                  <h6>Height</h6>
                  <p>12 ft</p>
                </div>
              </Col>
              <Col sm="auto">
                <div className="size-area">
                  <h6>Light condition</h6>
                  <p>Good</p>
                </div>
              </Col>
            </Row>

            <div className="facilities">
              <h5>Most popular facilities</h5>
              <ul>
                <li>Free WiFi</li>
                <li>Restaurant</li>
                <li>Airport shuttle</li>
                <li>Non-smoking rooms</li>
              </ul>
            </div>
          </Col>
          <Col sm={4}>
            <div className="mapouter">
              <div className="gmap_canvas">
                <iframe
                  title="Google Maps"
                  width="100%"
                  height="100%"
                  src={`https://maps.google.com/maps?q=${data?.location_name}&t=&z=5&ie=UTF8&iwloc=&output=embed`}
                  frameBorder="0"
                  scrolling="no"
                  marginHeight="0"
                  marginWidth="0"
                ></iframe>

                <style>
                  {`.mapouter{position:relative;text-align:right;height:100%;width:100%;}`}
                </style>

                <style>
                  {`.gmap_canvas{overflow:hidden;background:none!important;height:100%;width:100%;}`}
                </style>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Sublocation locationid={locationid} />
    </>
  );
};

export default Locationdetails;
