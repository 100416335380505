import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import user from "../Images/user.png";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Sublocation from "./Sublocation";
import { Review } from "./Testimonials";

const ProductPage = () => {
  const navigate = useNavigate();

  var id = useParams();
  console.log(id);
  const [data, setdata] = useState([]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://senpai.co.in/admin/api/a1/getSubLocationDetail?sub_location_id=${id.sublocationid}`
      );
      // console.log(response.data.data);
      setdata(response.data.data);
      console.log("location Details", response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    console.log("id.sublocationid", id.sublocationid);

    fetchData();
  }, [id.sublocationid]);

  const [date, setDate] = useState("");
  const [validationError, setValidationError] = useState("");

  const handleDateChange = (event) => {
    const selectedDate = event.target.value;
    setDate(selectedDate);

    // Clear previous validation error if any, when the user selects a date.
    setValidationError("");
  };

  const handleBooking = () => {
    // Perform the validation
    if (!date) {
      // If the date is not selected, show the validation error message.
      setValidationError("Please select a date.");
    } else {
      // Use the 'date' value and other data to construct the URL
      const url = `/bookings?property=${data?.sub_location_id}&country=${data?.country_name}&state=${data?.state_name}&city=${data?.city_name}&category_id=${data?.category_id}&location_id=${data?.location_id}&property_owner=${data?.property_owner}&sub_location_id=${data?.sub_location_id}&propertyName=${data?.sub_location_name}&country_id=${data?.country_id}&state_id=${data?.state_id}&city_id=${data?.city_id}&property_owner_id=${data?.property_owner_id}&date=${date}`;
      navigate(url);
      // Perform any other actions or navigation here
    }
  };

  return (
    <div className="container py-4">
      <div className="row my-5">
        <div className="col-sm-6">
          <Carousel
            showThumbs={true}
            thumbWidth={100}
            infiniteLoop={true}
            showArrows={true}
          >
            {data?.image?.map((val, index) => (
              <div key={index} className="image-slider">
                <img src={val} alt="" />
              </div>
            ))}
          </Carousel>
        </div>

        <div className="col-sm-6">
          <h1 className="top-heading-section text-start  ">
            <span> {data?.sub_location_name} </span>
          </h1>
          <div className="star-rating d-flex gap-3 mb-3">
            <div className="d-flex gap-1">
              <span className="fal fa-star checked"></span>
              <span className="fal fa-star checked"></span>
              <span className="fal fa-star checked"></span>
              <span className="fal fa-star"></span>
              <span className="fal fa-star"></span>
            </div>
            <p className="mb-0">2 Reviews</p>
          </div>

          <div className="price-date d-flex  gap-5 mb-2 align-items-center">
            <h3>₹{data?.price}</h3>
            <div className="formfield">
              {/* <i className="fal fa-calendar-alt me-1"> </i>{" "} */}
              <input
                type="date"
                name=""
                id=""
                value={date}
                className="form-control mb-0"
                onChange={handleDateChange}
              />
            </div>
          </div>

          {/* Validation error message */}
          {validationError && <p className="text-danger">{validationError}</p>}

          <div className="about-section">
            <p>{data?.sub_description}</p>
            <button className="btn btn-booknow px-5" onClick={handleBooking}>
              Book Now
            </button>
          </div>
        </div>
      </div>

      <Review />

      <Sublocation locationid={`${data?.location_id}`} />
    </div>
  );
};

export default ProductPage;
