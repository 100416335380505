import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import { Image } from "react-bootstrap";

const Bestdeals = () => {
  const [locationData, setLocationData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://senpai.co.in/admin/api/a1/getLocationList"
        );
        // console.log(response.data.data);
        const locationData = response.data.data;  
        const bestDeals = locationData.filter(
          (item) => item.tag_name === "Best deals for you"
        );
        setLocationData(bestDeals);
        console.log("bestDeals", bestDeals);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  const owlOptions = {
    loop: true,
    margin: 20,
    items: 4,
    dots: true,
    nav: true,
    navText: [
      "<i class='fas fa-chevron-left'></i>",
      "<i class='fas fa-chevron-right'></i>",
    ],
    responsive: {
      0: {
        items: 1 // Display 1 item at 320px and below
      },
      768: {
        items: 3 // Display 3 items between 768px and 1023px
      },
      1024: {
        items: 4 // Display 4 items at 1024px and above
      }
    }
  };
  return (
    <div className="container">
      <h1 className="top-heading-section my-3">
        <span>Best Deals</span> for you
      </h1>

      <p className="properties-in-india mt-5 pt-3">Properties in India</p>

      {locationData.length > 0 ? (
        <OwlCarousel className="owl-theme-1" {...owlOptions}>
          {locationData.map((location) => (
            <Link
              to={`/locationdetalis/${location.location_id}`}
              key={location.location_id}
            >
              <div className="best-deal-item">
                <Image src={location.image[0]} alt="" />
                <div>
                  <Link
                    to={`/booking`}
                    className="btn"
                  >
                    Book Now
                  </Link>
                </div>
              </div>
            </Link>
          ))}
        </OwlCarousel>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default Bestdeals;
