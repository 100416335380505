export const team1 = require("../Images/team1.png");
export const team2 = require("../Images/team2.png");
export const team3 = require("../Images/team3.png");
export const team4 = require("../Images/team4.png");
export const cateroy1 = require("../Images/cateroy1.png");
export const cateroy2 = require("../Images/cateroy2.png");
export const cateroy3 = require("../Images/cateroy3.png");
export const cateroy4 = require("../Images/cateroy4.png");
export const about = require("../Images/about.png");
export const user = require("../Images/user.png");

export const google = require("../Images/flat-color-icons_google.png");


